import Router from 'next/router'
import { trim, has, pickBy, replace } from 'lodash'
import {
	getConfigValue,
	decodeQueryString,
	encodeQueryString,
} from '@sm/client/lib'

const pageRoutes = {
	'my-shows': '',
	'shows-all': 'shows',
	'show-archive': 'archive',
	'show-calendar': 'calendar',
	'show-backlog': 'backlog',
	'announcement-schedule': 'schedule',
	'edit-show': 'shows/edit/:id',
	// 'show-backlog': 'backlog',
	options: 'settings',
	users: 'settings/users',
	publishing: 'settings/publishing',
	formats: 'settings/formats',
	brands: 'settings/brands',
	contacts: 'settings/contacts',
	'billing-order': 'settings/billing-order',
}

export const createPageUrl = (href: string, queryParams?: any): string => {
	const [path, queryString] = href.split('?')
	const pageName = trim(path, '/')

	let query

	if (queryParams || queryString) {
		let mergedQuery = {}
		if (queryString) {
			mergedQuery = decodeQueryString(queryString)
		}
		if (queryParams) {
			mergedQuery = {
				...mergedQuery,
				...queryParams,
			}
		}
		query = mergedQuery
	}

	let url = getConfigValue('url')

	if (has(pageRoutes, pageName)) {
		let path = pageRoutes[pageName]

		if (query) {
			const interpolatedParams = pickBy(
				query,
				(v, k) => path.indexOf(`:${k}`) > -1,
			)
			path = replace(path, /:([a-z]+)/, (match, name) => {
				// console.debug(query, interpolatedParams)
				if (interpolatedParams[name]) {
					return query[name]
				} else {
					throw Error(`cannot interpolate param "${name}"`)
				}
			})
			const otherParams = pickBy(query, (v, k) => !interpolatedParams[k])
			if (Object.keys(otherParams).length) {
				const encodedQuery = encodeQueryString(otherParams)
				path += `?${encodedQuery}`
			}
		}
		url += `/${path}`
	} else {
		url += `/${pageName}`
		if (query) {
			const encodedQuery = encodeQueryString(query)
			url += `?${encodedQuery}`
		}
	}

	return url
}

export const replaceRoute = (
	href: string,
	as?: string,
	opts: object = {},
): Promise<boolean> => {
	if (typeof window === 'undefined') {
		return Promise.resolve(true)
	}
	const options = {
		shallow: Boolean(as),
		...opts,
	}

	return Router.replace(href, as, options)
}

export const pushRoute = (
	href: string,
	as: string,
	opts: object = {},
): Promise<boolean> => {
	if (typeof window === 'undefined') {
		return Promise.resolve(true)
	}
	const options = {
		shallow: true,
		...opts,
	}

	return Router.push(href, as, options)
}
