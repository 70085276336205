import { createContext } from 'react'
import { UserData } from '@sm/server/models'

interface ViewerContextData {
	user: UserData | null
}

export const ViewerContext = createContext<ViewerContextData>({ user: null })

export const ViewerProvider = ({ children, user }) => {
	return (
		<ViewerContext.Provider value={{ user }}>{children}</ViewerContext.Provider>
	)
}
