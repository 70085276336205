import React, { useMemo } from 'react'
import clsx from 'clsx'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { UrlObject } from 'url'
import { createPageUrl, getConfigValue } from '@sm/client/lib'

// type LinkPropsUnion = NextLinkProps & React.HTMLAttributes<HTMLAnchorElement>
// type LinkPropsUnion = NextLinkProps
interface LinkProps {
	children?: React.ReactNode
	title?: string
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
	className?: string
	href?: string | UrlObject
	as?: string | UrlObject
	icon?: string
	shallow?: boolean
	bare?: boolean
}

export const Link: React.FC<LinkProps> = ({
	children,
	className: _className,
	onClick,
	href,
	as: asHref,
	shallow = true,
	icon,
	bare,
	...otherProps
}: any) => {
	const className = clsx(
		_className,
		// 'link',
		{
			[`icon--${icon}`]: !!icon,
		},
	)

	const as = useMemo(() => {
		if (asHref) {
			if (!asHref.startsWith('http')) {
				const baseUrl = getConfigValue('url')
				return `${baseUrl}${asHref}`
			}
			return asHref
		}

		if (href) {
			return createPageUrl(href)
		}
	}, [asHref, href])

	if (onClick || !href) {
		return (
			<a
				href="#"
				className={className}
				onClick={(e) => {
					e.preventDefault()
					onClick(e)
				}}
			>
				<span>{children}</span>
			</a>
		)
	}

	return (
		<NextLink href={href} shallow={shallow} as={as}>
			<a className={className} {...otherProps}>
				{bare && children}
				{!bare && <span>{children}</span>}
			</a>
		</NextLink>
	)
}
