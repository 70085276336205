// https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript#answer-13419367
export const decodeQueryString = (queryString: string) => {
	const query = {}
	const pairs = (
		queryString[0] === '?' ? queryString.substring(1) : queryString
	).split('&')
	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].split('=')
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
	}
	return query
}

export const encodeQueryString = (query: any): string => {
	return new URLSearchParams(query).toString()
}
