import React, { createContext, useMemo, ReactNode } from 'react'
// import { gql, useQuery, ApolloConsumer } from '@apollo/client'
import { NextPage } from 'next'
// import { useRouter } from 'next/router'
// import { ServerResponse } from 'http'
// import { UserData } from '@sm/server/models'
// import { Capability } from '@sm/server/types'
// import ErrorPage from '@sm/client/pages/_error'
import { initializeApollo } from '@sm/client/lib'
import { ApolloProvider } from '@apollo/client'

export const withApollo =
	() =>
	(PageComponent: NextPage): ReactNode => {
		const WithApollo = ({ initialApolloState, ...props }) => {
			// console.debug(
			// 	'WithApollo',
			// 	Boolean(initialApolloState),
			// 	Object.keys(props),
			// )
			const client = useMemo(() => {
				return initializeApollo(initialApolloState)
			}, [initialApolloState])
			// const client = initializeApollo(initialApolloState)

			return (
				<ApolloProvider client={client}>
					<PageComponent {...props} />
				</ApolloProvider>
			)
		}

		WithApollo.getInitialProps = async (context: any) => {
			// console.debug('WithApollo getInitialProps', Object.keys(ctx))
			const apolloClient = initializeApollo(null, context.req)

			const newContext = {
				...context,
				apolloClient,
			}

			return {
				...(PageComponent.getInitialProps &&
					(await PageComponent.getInitialProps(newContext))),
				initialApolloState: apolloClient.cache.extract(),
			}
		}

		return WithApollo
	}
