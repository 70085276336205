import React, { createContext, useEffect, useMemo, ReactNode } from 'react'
import { gql, ApolloClient } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { NormalizedCacheObject } from '@apollo/client/cache'
import { NextPage } from 'next'
// import { useRouter } from 'next/router'
import { ServerResponse } from 'http'
import { UserData } from '@sm/server/models'
import { Capability } from '@sm/server/types'
import { getConfigValue, ViewerProvider } from '@sm/client/lib'

const ViewerQuery = gql`
	query ViewerQuery {
		viewer {
			id
			email
			person {
				id
				firstName
				lastName
				nickname
				image {
					id
					name
					path
					size
					mime
					meta {
						crop {
							x
							y
							width
							height
						}
					}
				}
			}
			# TODO role properties always null
			roles {
				name
			}
			capabilities
		}
	}
`

export interface PageAuthOptions {
	isPublic?: boolean
	capability?: Capability
}

export const withAuth =
	({ capability, isPublic }: PageAuthOptions) =>
	(PageComponent: NextPage): ReactNode => {
		const WithAuth = ({ user, ...props }) => {
			// console.debug('WithAuth', user, Object.keys(props))
			const usersOnly = !isPublic || capability

			const { data, loading } = useQuery(ViewerQuery, {
				// pollInterval: 1000,
			})

			useEffect(() => {
				if (usersOnly && !loading && !data?.viewer) {
					window.location.reload()
				}
			}, [data?.viewer])

			return (
				<ViewerProvider user={loading ? user : data?.viewer}>
					<PageComponent {...props} />
				</ViewerProvider>
			)
		}

		WithAuth.getInitialProps = async (ctx: any) => {
			// console.debug('withAuth getInitialProps ctx', Object.keys(ctx))
			const baseUrl = getConfigValue('url')
			const apolloClient: ApolloClient<NormalizedCacheObject> = ctx.apolloClient
			let user: UserData | null = null

			if (apolloClient) {
				const viewerQuery = await apolloClient.query({
					query: ViewerQuery,
				})

				user = viewerQuery.data.viewer as UserData
				const res = ctx.res as ServerResponse
				const usersOnly = !isPublic || capability

				// console.debug('viewer is', viewerQuery)

				if (usersOnly && !user) {
					// console.debug(
					// 	'redirecting to login from',
					// 	ctx.asPath,
					// )

					let location = `/login`
					const path = ctx.asPath

					if (path !== '/') {
						const encodedRef = encodeURIComponent(path)
						location = `${location}?referrer=${encodedRef}`
					}

					if (res) {
						res.writeHead(302, { location })
						res.end()
					} else if (typeof window !== 'undefined') {
						window.location.replace(`${baseUrl}${location}`)
						// While the page is loading, code execution will
						// continue, so we'll await a never-resolving
						// promise to make sure our page never
						// gets rendered.
						await new Promise((resolve) => {})
					}

					return
				}

				if (capability && !user.capabilities.includes(capability)) {
					const error = {
						title: 'Access denied',
						statusCode: 401,
					}

					if (res) {
						res.statusCode = 401
					}

					return {
						error,
						user,
					}
				}
			}

			return {
				...(PageComponent.getInitialProps &&
					(await PageComponent.getInitialProps(ctx))),
				user,
			}
		}

		return WithAuth
	}
