import { flowRight } from 'lodash'

import { withApollo, withAuth, withReferrer, PageAuthOptions } from './hocs'

interface PageOptions extends PageAuthOptions {
	ssr?: boolean
}

export const composePage =
	({ capability, isPublic = false }: PageOptions = {}) =>
	(PageComponent) =>
		flowRight(
			withApollo(),
			withAuth({
				capability: capability,
				isPublic: isPublic && !capability,
			}),
			// withReferrer(),
		)(PageComponent)
