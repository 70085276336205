import { ApolloClient, ApolloLink } from '@apollo/client'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import merge from 'deepmerge'
import { isEqual } from 'lodash'
import { getConfigValue, getConfig } from '@sm/client/lib'

let apolloClient

function createApolloClient() {
	const {
		url: baseUrl,
		debug: isDebug,
	} = getConfig()

	const errorLink = onError(({ graphQLErrors }) => {
		if (graphQLErrors) {
			for (const graphQLError of graphQLErrors) {
				if (graphQLError.message === 'Access denied') {
					// TODO better: show login modal?
					window.location.reload()
				}

				const error = new Error(graphQLError.message)
				error.stack = graphQLError?.extensions?.exception?.stacktrace
					?.map((line) => `${line}\n`)
					.join('')

				console.error(error)
			}
		}
	})

	const uploadLink = createUploadLink({
		uri: `${baseUrl}/api/graphql`,
		credentials: 'same-origin',
		// headers,
		fetch,
		// useGETForQueries: true,
	})

	return new ApolloClient({
		ssrMode: false,
		connectToDevTools: isDebug,
		link: ApolloLink.from([
			//
			// contextLink,
			errorLink,
			// httpLink,
			uploadLink,
		]),
		cache: new InMemoryCache({
			// V2
			// dataIdFromObject: (object: any) => {
			// 	switch (object.__typename) {
			// 		case 'Event':
			// 			return `Event:${object.uid}`
			// 		case 'EventMeta':
			// 			return `EventMeta:${object.uid}`
			// 	}
			// 	return defaultDataIdFromObject(object)
			// },
			// V3
			typePolicies: {
				Event: {
					keyFields: ['uid'],
				},
				EventMeta: {
					keyFields: ['uid'],
				},
			},
		}),
	})
}

export function createBrowserClient(
	initialState = null,
): ApolloClient<NormalizedCacheObject> {
	const client = apolloClient ?? createApolloClient()

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = client.extract()

		// Merge the existing cache into data passed from getStaticProps/getServerSideProps
		const data = merge(initialState, existingCache, {
			// combine arrays using object equality (like in sets)
			arrayMerge: (destinationArray, sourceArray) => [
				...sourceArray,
				...destinationArray.filter((d) =>
					sourceArray.every((s) => !isEqual(d, s)),
				),
			],
		})

		// Restore the cache with the merged data
		client.cache.restore(data)
	}

	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = client

	return client
}
