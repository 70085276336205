import React, { useMemo, FunctionComponent, useEffect } from 'react'
import NextLink from 'next/link'
// import NextImage from 'next/image'
import { isObject, isString, sum } from 'lodash'

// import queryString from 'query-string'

import { FileData } from '@sm/server/models'
import { encodeQueryString } from '@sm/client/lib'

// interface ImageSource {
// 	url?: string
// }

interface ImageProps {
	src?: string | object
	file?: any
	className?: string
	height?: number
	width?: number
	crop?: boolean
}

// TODO currently source can be
// - a FileData object
// - something like a FileData object (event image data)
// - a bloburl / string
// - a url / string

export const Image: React.FC<ImageProps> = ({
	className,
	src: source,
	file,
	width,
	height,
	crop,
}: any) => {
	const query = useMemo(() => {
		const params: any = {}

		if (width) {
			params.width = width
		}

		if (height) {
			params.height = width
		}

		if (crop) {
			params.crop = 1
		}

		if (file?.crop) {
			params.cropX = file.crop.x
			params.cropY = file.crop.y
			params.cropWidth = file.crop.width
			params.cropHeight = file.crop.height
		}

		return params
	}, [width, height, file])

	// as cache breaker
	const imageKey = useMemo(() => {
		if (isObject(source)) {
			const file = source as any
			const cropState = file?.meta?.crop ?? file?.crop
			if (cropState && crop) {
				return sum(Object.values(cropState))
			}
		}

		if (file) {
			const cropState = file?.meta?.crop ?? file?.crop
			if (cropState && crop) {
				return sum(Object.values(cropState))
			}
		}

		return source
	}, [source, file])

	// console.debug('key', imageKey)

	const src = useMemo(() => {
		if (isObject(source)) {
			// console.debug('[image] loading from object source', source)
			const file = source as FileData
			const path = `images/${encodeURIComponent(file.name)}`
			const queryStr = encodeQueryString(query)
			if (queryStr) {
				return `/${path}?${queryStr}`
			}
			return path
		} else if (isString(source)) {
			// console.debug('[image] loading from string source', source)
			if (source.startsWith('blob:')) {
				return source
			}
			const queryStr = encodeQueryString(query)
			if (queryStr) {
				return `${source}?${queryStr}`
			}
			return source
		}

		return source
	}, [file, source, query])

	// useEffect(() => {
	// 	console.debug('image file:', imageKey, file)
	// }, [imageKey])

	return <img key={imageKey} className={className} src={src} />
	// return (
	// 	<NextImage key={imageKey} className={className} src={src} layout="fill" />
	// )
}
