import React, { useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from '@apollo/client'
import { ViewerContext } from '@sm/client/lib'
import { UserData } from '@sm/server/models'

export interface Viewer {
	user: UserData | null
	can: (cap: string) => boolean
	logout: () => Promise<void>
}

export const LogoutMutation = gql`
	mutation Logout {
		logout
	}
`

export const useViewer = (): Viewer => {
	const { user } = useContext(ViewerContext)
	const [logout] = useMutation(LogoutMutation)

	return {
		user,
		// is: (role) =>
		can: (cap) => !!user?.capabilities.includes(cap),
		logout: async () => {
			await logout()
			window.location.reload()
		},
	}
}
