import { ApolloClient } from '@apollo/client'
import { NormalizedCacheObject } from '@apollo/client/cache'

import { createSSRClient } from './ssr'
import { createBrowserClient } from './browser'

// https://github.com/vercel/next.js/issues/10413

export function initializeApollo(
	initialState = null,
	req = null,
): ApolloClient<NormalizedCacheObject> {
	if (typeof window === 'undefined') {
		return createSSRClient(initialState, req)
	}
	return createBrowserClient(initialState)
}
